.custom-pagination {
  font-size: 64px;
  color: #b59278 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet {
  width: 25px;
  height: 8px;
  border-radius: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 5px;
  color: #000;
  opacity: 1;
  background: #2c2625;
}

.swiper-pagination-bullet-active {
  color: #fff;
  width: 40px;
  border-radius: 20px;
  background: #453b39;
}

.swiper-custom-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.custom-quill .ql-toolbar {
  background-color: #B59278;
  border-radius: 8px 8px 0 0;
  color: white; /* Set label text color to white */
}

.custom-quill .ql-container {
  border-radius: 0 0 8px 8px;
  height:30vh;
  color: white; /* Ensure the text inside the editor is white */
}

/* Change the color of icons and labels in the toolbar */
.custom-quill .ql-toolbar .ql-picker-label,
.custom-quill .ql-toolbar button {
  color: white; /* Set toolbar icons and button labels to white */
}

/* General reset for zoomed-in arrows issue */
.ql-toolbar .ql-picker-label::before,
.ql-toolbar .ql-picker-options {
  display: none; /* Hide the extra arrows */
}

.custom-quill .ql-picker {
  background: none;
}

.custom-quill .ql-picker-label {
  padding: 0;
  color: white; /* Ensure the picker label is also white */
}

/* Ensure the placeholder text in the editor is white */
.custom-quill .ql-editor::before {
  color: rgba(255, 255, 255, 0.6); /* White placeholder with some opacity */
}

