#scroll-down-arrow {
    animation: down 1.5s infinite;
    -webkit-animation: down 1.5s infinite;
  }

  @keyframes down {
    0% {
      transform: translate(0);
    }
    20% {
      transform: translateY(5px);
    }
    40% {
      transform: translate(0);
    }
  }
  
  @-webkit-keyframes down {
    0% {
      transform: translate(0);
    }
    20% {
      transform: translateY(5px);
    }
    40% {
      transform: translate(0);
    }
  }
  