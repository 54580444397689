.loader {
    overflow: hidden;
    position: relative;
    height: 100vh;
    
  }
  
  .wheel {
    fill: rgb(128, 128, 128);
    font-size: 100px;
    width: 150px;
    height: 150px;
    margin: 200px auto;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 6em;
    text-align: center;
    animation: rolling 1s linear infinite;
  }
  
  #rim {
    fill: rgb(126, 126, 126);
  }
  
  #tire {
    fill: rgb(55, 55, 55);
  }
  
  .road {
    position: absolute;
    margin-top: -200px;
    width: 100%;
    height: 10px;
    border-radius: 5em;
    background-color: rgb(55, 55, 55);
    animation: road-move 2s linear infinite;
  }
  
  @keyframes rolling {
    to {
      transform: rotate(-360deg);
    }
  }
  
  @keyframes road-move {
    from {
      transform: translateX(-140%);
    }
  
    to {
      transform: translateX(100%);
    }
  }
  